@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: 'Cabin', arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-heading {
  font-weight: 700;
  font-size: 22px;
  font-family: 'Cabin', arial, sans-serif;
  color: rgb(226, 226, 226);
  
  /* background-color: #e2e2e2;
  color: rgb(0 0 0); */
}
.logo-heading-top {
  font-weight: 700;
  font-size: 22px;
  font-family: 'Cabin', arial, sans-serif;
  /* color: rgb(226, 226, 226); */
  font-size: 12px;
  padding-bottom: 8px;
  background-color: #e2e2e2;
  color: rgb(0 0 0);
}
.ml-24 {
  margin-left: 2rem;
}

.malhar-bg-dark {
  background-color: rgba(0, 0, 0, 0.25)
}

.malhar-band-image {
  width: 100%;
}

.absolute {
  position: absolute
}

.top-0 {
  top: 0px
}
.logobg{
  height: 44px;
    margin: 0.4rem;
}
.parallax-container {
  text-transform: uppercase;

  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* Adjust this value to control the height of the parallax section */
  text-align: center;
  color: white;
  padding: 28vh 0px;
  /* Adjust padding to center content vertically */
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the last value (0.5) for opacity */

}

.parallax-content {
  text-transform: uppercase;
  position: absolute;
  padding: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  top: 0;
  background-color: rgb(0 0 0 / 24%);
  width: 100%;
  opacity: 0.5;

}

.section-1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)), url('./assets/images/banner-malhar.jpg');


}

h1 {
  border-left: 1px solid rgba(247, 247, 247, 0.5);
  margin: 0 7rem;
  text-align: left;
  padding: 1rem 2rem;
}

.malhar-button {
  background-color: rgb(249, 247, 235);
  color: rgb(42, 42, 41);
  min-height: 56px;
  padding: 10px 22px;
  border: none;
  margin-top: 10px;
  width: 160px;
  font-weight: 700;
  font-size: 16px;
}

.bg-black {
  background: black;
}

.bg-grey {
  background: #161616;

}

.bb-1 {
  border-bottom: 1px solid rgba(247, 247, 247, 0.5);

}

.align-page {
  padding: 1rem 2rem;
}

.uppercase {
  text-transform: uppercase;
}

.section-1 {
  max-height: 70vh;
}

.card {
  text-align: center
}

.card-img-top {
  border-radius: 50% !important;
  padding: 1rem;
}

.card {
  color: white;
  background: black;
}

.card-body {
  color: var(--bs-card-color);
  font-family: Lato, arial, sans-serif;
}

p.card-text {
  color: rgb(145, 145, 145);
  font-family: Lato, arial, sans-serif;
  font-size: 16px;
  letter-spacing: normal;
  font-weight: 400;
  margin-top: 2rem;
  padding: 0 1rem;
}

ul {
  display: flex;
}

ul li {
  float: left;
}

.member-list {
  display: flex;
  flex-wrap: wrap;
}

.text-grey {
  color: rgb(226, 226, 226);
}

.normal-letter-spacing {
  letter-spacing: normal;
}

.font-12 {
  font-size: 12px;
}

.text-none {
  text-decoration: none;
}

.whatsapp {
  font-size: 16px;
  font-weight: 700;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 0.8rem 1rem;
}

.weight-500 {
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  h1.ml-24 {
    margin: 1rem;
    text-align: center;
  }

  .smallscreenFont,
  h3.bb-1.logo-heading.pb-3.smallscreenFont.text-left.uppercase,
  button.malhar-button,
  h3.bb-1.pb-3.uppercase.logo-heading.text-left {
    font-size: .9rem;
  }

  .malhar-button {
    background-color: rgb(249, 247, 235);
    color: rgb(42, 42, 41);
    min-height: 48px;
    padding: 10px 16px;
    border: none;
    margin-top: 10px;
    width: 149px;
    font-weight: 700;
    font-size: .7rem;
  }

  .parallax-container {
    padding: 18vh 0px;
  }
}

.insta {
  fill: grey;

  border-radius: 50%;
}
svg.image-gallery-svg {
  width: 1em!important;
}
